import initSlickWithArrowEvent from "../../components/slick-utils";

function initShopProductSectionProductSlick() {
    const slick = initSlickWithArrowEvent(
        $('.maier-section-product-visual-aside'),
        {
            vertical: true,
            dots: false,
            arrows: true,
            prevArrow: '<span class="slide-arrow prev">Prev</span>',
            nextArrow: '<span class="slide-arrow next">Next</span>',
            autoplay: false,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1
        }
    );

    let mobileSlick = $('.maier-section-product-visual-mobile').not('.slick-initialized').slick({
        vertical: false,
        dots: true,
        arrows: false,
        prevArrow: '<span class="slide-arrow prev">Prev</span>',
        nextArrow: '<span class="slide-arrow next">Next</span>',
        autoplay: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    arrows: false,
                    autoplay: false,
                    dots: true,
                    dotsClass: 'maier-section-product-visual-mobile-dots'
                }
            }
        ]
    });

    mobileSlick.on('afterChange', function(event, slick, currentSlide) {
        var activeDotEl = $(event.target).find('.maier-section-product-visual-mobile-dots').find('li.slick-active');
        if (activeDotEl.get(0)) {
            activeDotEl.get(0).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }
    });
}

function initProductOptionParameterEvents() {
    document.querySelectorAll('.product-option-parameter select').forEach(function (select) {
        select.addEventListener('focus', function () {
            this.size = 5;
        });

        select.addEventListener('blur', function () {
            this.size = 1;
        });

        select.addEventListener('change', function () {
            this.size = 1;
            this.blur();
        });
    });
}

document.addEventListener('DOMContentLoaded', function () {
    initShopProductSectionProductSlick();
    initProductOptionParameterEvents();

    if (null === document.querySelector('.maier-section-product-informations-main-footer-link-list-item')) {
        return;
    }

    let productFooterLinkModal = new Modal({
        'id': 'maier-modal-product-footer-link',
        'create': true,
    });

    new AjaxLink({
        selector: '.maier-section-product-informations-main-footer-link-list-item[data-modal]',
        targetElements: {
            '#maier-modal-product-footer-link > .modal-content .content' : 'main .content'
        },
        isTriggerReplace: false,
        callback: function() {
            productFooterLinkModal.show();
        }
    });
});