document.addEventListener('DOMContentLoaded', function () {
    let iframe = document.querySelector('.maier-container-configurator-fred > iframe');
    if (null !== iframe) {
        iframe.addEventListener('load', function () {
            window.addEventListener('message', (event) => {
                if (event.data.manille && event.data.cable){
                    if (!event.data.taille) {
                        Toast.error(this.getAttribute('data-error-size-message') ?? 'An error has occured.', 5000);

                        return;
                    }

                    let action = this.getAttribute('data-url-prototype');
                    let form = document.createElement("form");
                    form.method = 'GET';
                    form.action = action.replace('__fred_reference__', event.data.manille + "-" + event.data.cable + (event.data.taille ? "-" + event.data.taille : ""));

                    document.body.appendChild(form);
                    form.submit();
                }

            }, false);
        });
    }
})